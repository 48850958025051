@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
  }
  body{
    word-break: break-word;
  }
  p {
    font-size: 13px;
  }

  h3 {
    font-size: 16px;
  }

  h2 {
    font-size: 30px;
  }

  .col-xs-6 {
    padding: 0 5px;
  }
}
