/* Контейнер выпадающего меню */
.language-dropdown {
    position: relative;
    display: inline-block;
}

/* Кнопка переключения */
.dropdown-toggle {
    margin: 5px 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
    background-color: #007764;
    background-image: linear-gradient(to right, #007764 0%, #009E89 100%);
    padding: 5px 13px 5px 10px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-toggle:hover {
    background-color: #009E89;
}

.dropdown-toggle .flag {
    font-size: 24px;
    margin-right: 10px;
}

.dropdown-toggle .language-name {
    margin-right: 10px;
    color: #fff;
}

.dropdown-toggle .arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    padding: 4px;
    margin-left: auto;
    transform: rotate(45deg);
    transition: transform 0.2s;
}

.dropdown-toggle .arrow.down {
    transform: rotate(45deg);
}

.dropdown-toggle .arrow.up {
    transform: rotate(-135deg);
}

.dropdown-menu {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    min-width: 160px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    transition: background 0.2s;
}

.dropdown-item:hover {
    background-color: #f6f6f6;
}

.dropdown-item .flag {
    margin-right: 10px;
}

.dropdown-item .language-name {
    font-size: 14px;
    color: #333;
}

@media (max-width: 768px) {

    .dropdown-item {
        padding: 6px 10px;
    }
}

@media (max-width: 450px) {
     .language-name {
        display: none;
    }
    .dropdown-menu {
        min-width: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .dropdown-item .flag {
        margin-right: 0;
    }
}